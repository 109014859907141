<script setup lang="ts">
import { FirebaseError } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { ref } from "vue";
import { useFirebaseAuth } from "vuefire";
import { graphql } from "~/gql";
import { ActiveUserFragment } from "~/gql/graphql";
import { getFirebaseErrorMessage } from "~/utils/utils";
import GoogleLogo from "./GoogleLogo.vue";
import RegistrationChrome from "./RegistrationChrome.vue";
import * as EmailValidator from 'email-validator';
import { useMainStore } from "~/store";
import { ApolloClients } from "@vue/apollo-composable";
import { getRedirectUrl } from "~/utils/utils";

type RegistrationType = "login" | "join";

provide(ApolloClients, {
    auth: useApollo().clients?.auth,
})

const props = defineProps<{
    type: RegistrationType;
    inviteCode: string | undefined;
}>();

const googleAuthProvider = new GoogleAuthProvider();

const auth = useFirebaseAuth(); // only exists on client side
const router = useRouter();
const route = useRoute();

const error = ref<string | null>(null);
const email = ref("");

const inviteCodeCookie = useCookie<string | undefined>("invite_code");

const inviteCodeRef = ref<string | undefined>(inviteCodeCookie.value);

if (props.inviteCode) {
    inviteCodeRef.value = props.inviteCode;
    inviteCodeCookie.value = props.inviteCode;
}

const loggingIn = ref(false);
function continueWithGoogle() {
    error.value = null;
    signInWithPopup(auth!, googleAuthProvider)
        .then(async (credential) => {
            const firebaseUser = credential.user;
            loggingIn.value = true;
            await ensureApolloInitialized(firebaseUser);
            const civiqaUserResult = await ensureCiviqaUserOnClient({
                inviteCode: inviteCodeRef.value,
                networkOnly: true,
            });
            if (civiqaUserResult.missingEnviroPerson) {
                router.push('/request');
            } else if (civiqaUserResult.civiqaUser) {
                useMainStore().civiqaUser = civiqaUserResult.civiqaUser;
                router.push(getRedirectUrl(route.query.redirect as string | string[] | undefined));
            } else if (civiqaUserResult.error) {
                error.value = civiqaUserResult.error;
            } else {
                error.value =
                    "There was an error logging in. Please try again later.";
            }
        })
        .catch((reason: FirebaseError) => {
            error.value = getFirebaseErrorMessage(reason);
        })
        .finally(() => {
            loggingIn.value = false;
        })
}

const emailLoginUrl = computed(() => {
    let urlBase: string = useRuntimeConfig().public.apiBaseUrl as string
    if (urlBase.endsWith('/'))
        urlBase = urlBase.slice(0, -1)
    return urlBase + '/unauth/email-login'
})

async function continueWithEmail() {
    error.value = null;
    if (!showEmail.value) {
        showEmail.value = true;
        setTimeout(() => {
            document.getElementById("email")?.focus();
        }, 400);
        return;
    }
    if (!email.value) {
        error.value = "Please enter your email address";
        return;
    }
    if (!EmailValidator.validate(email.value)) {
        error.value = "Please enter a valid email address.";
        return;
    }
    sendingEmail.value = true;
    const params = []
    if (email.value) {
        params.push(`email=${email.value}`)
    }
    if (props.inviteCode) {
        params.push(`invite_code=${inviteCodeRef.value}`)
    }
    if (props.type === "join") {
        params.push("from_signup_flow=true");
    } else {
        params.push("from_signup_flow=false");
    }
    if (route.query.redirect) {
        params.push("redirect=" + route.query.redirect);
    }
    const url = emailLoginUrl.value + "?" + params.join("&");
    const result = await fetch(url, {
        method: 'GET',
    })
    if (!result.ok) {
        error.value = "There was an error sending the email. Please try again later."
    }
    sendingEmail.value = false;
    if (result.ok) {
        const data = await result.json()
        const success = data['success']
        const errorMessage = data['error']
        if (success) {
            window.localStorage.setItem("emailForSignIn", email.value);
            emailSent.value = true;
        } else if (errorMessage) {
            error.value = errorMessage;
        } else {
            error.value =
                "There was an error sending the email. Please try again later.";
        }
    } else {
        error.value = "There was an error sending the email. Please try again later."
    }
}

const showEmail = ref(false);
const continueWithGoogleRef = ref(null);
const sendingEmail = ref(false);
const emailSent = ref(false);

const title = computed(() =>
    emailSent.value
        ? "Check your email"
        : props.type === "join"
            ? "Join CEQAbase"
            : "Log in to CEQAbase",
);

const subtitle = props.type === 'login' ? "Choose a login method below to continue" :
    "Claim your invite by selecting a login method.";

</script>

<template>
    <RegistrationChrome :title="title" :subtitle="subtitle" :loading="loggingIn">
        <div v-if="emailSent" class="flex flex-col items-center">
            <div class="mt-6 text-center font-normal leading-6 text-gray-900">
                We've sent a login link to
                <span class="font-semibold">{{ email }}</span>
                <br />
                <br />
                Please check your inbox and click the link in the email to
                continue.
            </div>

            <button @click="emailSent = false" class="mt-12 text-sm text-gray-400 hover:text-blue-500">
                {{ type === 'login' ? 'Back to login' : 'Back to login' }}</button>
        </div>
        <div v-else class="mt-4 flex w-full flex-col items-stretch">
            <div class="mt-6 grid w-full grid-cols-1 gap-4">
                <button ref="continueWithGoogleRef"
                    class="flex h-12 w-full items-center justify-center gap-x-3 rounded-md bg-green-600 px-3 text-xs leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    @click="continueWithGoogle()">
                    <GoogleLogo class="max-h-4 max-w-4" :white="true" />
                    <span class="text-sm font-normal leading-6">
                        Continue with Google
                    </span>
                </button>
            </div>

            <div class="overflow-hidden transition-all delay-100 duration-300 ease-in-out" :class="{
        'min-h-18 h-24 max-h-24 opacity-100': showEmail,
        'h-0 max-h-0 min-h-0 opacity-0': !showEmail,
    }">
                <div class="relative py-6">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                    </div>
                </div>
                <div class="relative">
                    <input v-if="showEmail" id="email" v-model="email" name="email" type="email" autocomplete="email"
                        required placeholder="Enter your email address..."
                        class="h-12 w-full rounded-md border-0 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        @keydown.enter="continueWithEmail()" :class="{
        'ring-red-500': error,
        'bg-red-50 focus:bg-white': error,
    }" />
                </div>
            </div>
            <div v-if="error" class="mt-4 rounded-md bg-red-50 px-3 py-2 text-sm text-red-700 ring-1 ring-red-800/10">
                {{ error }}
            </div>
            <div class="relative mt-4">
                <button type="button"
                    class="h-12 w-full rounded-md px-3.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 "
                    :class="{
        'bg-gray-100': sendingEmail,
        'bg-white hover:bg-gray-50': !sendingEmail,
    }" :disabled="sendingEmail" @click="continueWithEmail()">
                    Continue with Email
                </button>
                <span v-if="sendingEmail"
                    class="absolute right-5 top-3.5 size-5 animate-spin rounded-full border-b-2 border-gray-900" />
            </div>
            <div class="text-sm mt-10 text-center text-gray-400 font-normal">
                By continuing, you agree to our <NuxtLink class="font-medium hover:text-blue-500" href="/terms">Terms of
                    Service</NuxtLink> and our <NuxtLink href="/privacy" class="font-medium hover:text-blue-500">Privacy
                    Policy</NuxtLink>.
            </div>
        </div>
    </RegistrationChrome>
</template>
